import { configureStore } from '@reduxjs/toolkit';
import favourateSlice from '../slices/favourateSlice';
import productReducer from '../slices/productSlice';
import userReducer from '../Reducers/userReducer';

const store = configureStore({
    reducer: { user: userReducer }
});

export default store;
