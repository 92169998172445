import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { baseUrl } from "../../baseUrl";
import Layout from "../Layout/Layout";
import loaderImg from "../../assets/images/loader.svg";

function Privacycenter() {
  const [loading, setloading] = useState(false);

  const [about, setabout] = useState();
  console.log(about);
  const fetchtownList = async () => {
    await axios
      .get(baseUrl + `/cookies`, {
        headers: { Authorization: `No Auth` },
      })
      .then((res) => {
        console.log("", res);
        if (res.status == 200) {
          setabout(res.data.data);
          setloading(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          setloading(true);
        }
      });
  };

  useEffect(() => {
    fetchtownList();
    window.scrollTo(0,0)
  }, []);

  return (
    <>
      <Layout>
        {loading !== true ? (
          <div className="loader_set">
            {" "}
            <img src={loaderImg} alt="loading..." />{" "}
          </div>
        ) : (
          <section className="comman-contents">
            <div className="container-sm">
              <div className="center-heading">
                <h1>Privacy Centre</h1>
              </div>
              <div className="inner-content">
                <ul className="list_box flex">
                {about[0] ? 
                  <li>
                    <NavLink to={`/privacy-policy/${about[0].id}`}>
                      <i aria-hidden="true" className="fas fa-glasses"></i>
                      <h5>{about ? about[0].title :""}</h5>
                    </NavLink>
                  </li> :""}
                  <li>
                    <NavLink to={`/code-of-conduct/${about[2].id}`}>
                      <i aria-hidden="true" className="far fa-handshake"></i>
                      <h5>{about[2] ? about[2].title :""}</h5>

                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={`/user-terms/${about[1].id}`}>
                      <i aria-hidden="true" className="fas fa-user-friends"></i>
                      <h5>{about[1] ? about[1].title :""}</h5>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={`/terms-conditions/${about[3].id}`}>
                      <i aria-hidden="true" className="fas fa-user-tie"></i>
                      <h5>{about[3] ? about[3].title :""}</h5>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        )}
      </Layout>
    </>
  );
}

export default Privacycenter;
