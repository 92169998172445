import React from 'react'
import { Navigate } from 'react-router-dom'
import AgentTerms from "../../AgentData/AgentTerms"
import UserTerms from "../../Home/UserTerms"


function AgentPrivateRoute(props) {

  const user = JSON.parse(localStorage.getItem("data"))

  const usertype = user.data.user.user_type
  console.log(usertype);

  return (
    <>
      {
        usertype == "Agent" ? props.element : <Navigate to="/user-terms" />
      }
      AgentPrivateRoute


    </>
  )
}

export default AgentPrivateRoute