const { createSlice } = require('@reduxjs/toolkit');

const favourateSlice = createSlice({
    name: 'cart',
    initialState: [],
    reducers: {
        add(state, action) {
            state.push(action.payload);
        },
        remove(state, action) {
            return state.filter((item) => item.id !== action.payload);
        },
    },
});

export const { add, remove } = favourateSlice.actions;
export default favourateSlice.reducer;