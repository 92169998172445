import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import image1 from "../../assets/images/why-lists-img1.jpg";
import image2 from "../../assets/images/why-lists-img2.jpg";
import image3 from "../../assets/images/why-lists-img3.jpg";
import image4 from "../../assets/images/why-lists-img4.jpg";
import image5 from "../../assets/images/why-lists-img5.jpg";
import image6 from "../../assets/images/why-lists-img6.jpg";
import image7 from "../../assets/images/why-lists-img7.jpg";
import image8 from "../../assets/images/why-lists-img8.jpg";
import GetIntouch from "../Sections/HomeSections/GetIntouch";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import loaderImg from "../../assets/images/loader.svg";

function Whyus() {
  const [show, setshow] = useState(false);

  const token = JSON.parse(localStorage.getItem("token"));
  const [details, setDetails] = useState([]);
  const [loading, setloading] = useState(false);

  console.log(details);
  const fetchbuyadvice = async () => {
    await axios
      .get(baseUrl + "/why-list-with-us", {
        headers: { Authorization: `No Auth` },
      })
      .then((res) => {
        if (res.status == 200) {
          setloading(true);
        }
        console.log(res);
        setDetails(res.data.data?.whywithuslistData);
      })
      .catch((error) => {
        if (error) {
          setloading(true);
        }
        console.log(error);
      });
  };

  useEffect(() => {
    fetchbuyadvice();
  }, []);

  return (
    <>
      <Layout>
      {loading !== true ? (
          <div className="loader_set">
            {" "}
            <img src={loaderImg} alt="loading..." />{" "}
          </div>
        ) : <>
        <section className="innerBanner innerBanner_Back" style={{backgroundImage: require("../../assets/images/why-list-with-us-img.jpg")}}>
          <div className="inner-heading">
            <h1>Why List With Us</h1>

          </div>
        </section>

        <section className="why-lists">
          <div className="wrapper_Mob  wrapper ">
            <div className="container-sm flex_mob  flex">
              <div className="col_mob col">
                <div className="heading-line" data-aos="fade-in">
                  <h3>
                    {details[0] ? details[0].title : ""}
                    </h3>
                </div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: details[0] ? details[0].description : "",
                  }}
                />
              </div>
              <div className="col_mob col">
                <figure>
                  <img src={details[0] ? details[0].file_path : ""} alt="loading img..." />
                </figure>
              </div>
            </div>
          </div>

          <div className=" wrapper_Mob wrapper">
            <div className="container-sm flex_mob flex">
              <div className="col_mob col">
                <figure>
                <img src={details[1] ? details[1].file_path : ""} alt="loading img..." />
                </figure>
              </div>
              <div className="col_mob col">
                <div className="heading-line" data-aos="fade-in">
                  <h3>
                  {details[1] ? details[1].title : ""}
                    </h3>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: details[1] ? details[1].description : "",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="wrapper_Mob  wrapper ">
            <div className="container-sm flex_mob  flex">
              <div className="col_mob col">
                <div className="heading-line" data-aos="fade-in">
                  <h3>
                    {details[2] ? details[2].title : ""}
                    </h3>
                </div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: details[2] ? details[2].description : "",
                  }}
                />
              </div>
              <div className="col_mob col">
                <figure>
                  <img src={details[2] ? details[2].file_path : ""} alt="loading img..." />
                </figure>
              </div>
            </div>
          </div>
          <div className=" wrapper_Mob wrapper">
            <div className="container-sm flex_mob flex">
              <div className="col_mob col">
                <figure>
                <img src={details[3] ? details[3].file_path : ""} alt="loading img..." />
                </figure>
              </div>
              <div className="col_mob col">
                <div className="heading-line" data-aos="fade-in">
                  <h3
                  >
                    {details[3] ? details[3].title : ""}
                  </h3>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: details[3] ? details[3].description : "",
                  }}
                />
              </div>
            </div>
          </div>



          <div className="wrapper_Mob  wrapper ">
            <div className="container-sm flex_mob  flex">
              <div className="col_mob col">
                <div className="heading-line" data-aos="fade-in">
                  <h3>
                    {details[4] ? details[4].title : ""}
                    </h3>
                </div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: details[4] ? details[4].description : "",
                  }}
                />
              </div>
              <div className="col_mob col">
                <figure>
                  <img src={details[4] ? details[4].file_path : ""} alt="loading img..." />
                </figure>
              </div>
            </div>
          </div>
          <div className=" wrapper_Mob wrapper">
            <div className="container-sm flex_mob flex">
              <div className="col_mob col">
                <figure>
                <img src={details[5] ? details[5].file_path : ""} alt="loading img..." />
                </figure>
              </div>
              <div className="col_mob col">
                <div className="heading-line" data-aos="fade-in">
                  <h3
                  >
                    {details[5] ? details[5].title : ""}
                  </h3>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: details[5] ? details[5].description : "",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="wrapper_Mob  wrapper ">
            <div className="container-sm flex_mob  flex">
              <div className="col_mob col">
                <div className="heading-line" data-aos="fade-in">
                  <h3>
                    {details[6] ? details[6].title : ""}
                    </h3>
                </div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: details[6] ? details[6].description : "",
                  }}
                />
              </div>
              <div className="col_mob col">
                <figure>
                  <img src={details[6] ? details[6].file_path : ""} alt="loading img..." />
                </figure>
              </div>
            </div>
          </div>
          { details[7] ?
          <div className=" wrapper_Mob wrapper">
            <div className="container-sm flex_mob flex">
              <div className="col_mob col">
                <figure>
                <img src={details[7] ? details[7].file_path : ""} alt="loading img..." />
                </figure>
              </div>
              <div className="col_mob col">
                <div className="heading-line" data-aos="fade-in">
                  <h3
                  >
                    {details[7] ? details[7].title : ""}
                  </h3>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: details[7] ? details[7].description : "",
                  }}
                />
              </div>
            </div>
          </div> :""}

         { details[8] ?
          <div className="wrapper_Mob  wrapper ">
            <div className="container-sm flex_mob  flex">
              <div className="col_mob col">
                <div className="heading-line" data-aos="fade-in">
                  <h3>
                    {details[8] ? details[8].title : ""}
                    </h3>
                </div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: details[8] ? details[8].description : "",
                  }}
                />
              </div>
              <div className="col_mob col">
                <figure>
                  <img src={details[8] ? details[8].file_path : ""} alt="loading img..." />
                </figure>
              </div>
            </div>
          </div> :""}
        </section>
        <section className="getInTouch">
          <div className="container-sm">
            <h2>Start advertising your properties today</h2>
            <a onClick={() => setshow(true)}>Get In Touch</a>
          </div>
        </section>

        {show === true ? <GetIntouch setfn={setshow} /> : ""}

        </>}
      </Layout>
    </>
  );
}

export default Whyus;
