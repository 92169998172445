import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import Homelayout from '../Layout/Homelayout'
import Layout from '../Layout/Layout'
import ExploreSection from '../Sections/HomeSections/ExploreSection'
import HomeServices from '../Sections/HomeSections/HomeServices'
import NewsLetter from '../Sections/HomeSections/NewsLetter'
import NewsSection from '../Sections/HomeSections/NewsSection'
import Slider from '../Sections/HomeSections/Slider'
import WeAre from '../Sections/HomeSections/WeAre'
import loaderImg from "../../assets/images/loader.svg";

function Home() {
  const user = useSelector((state) => state)
  const [loading, setloading] = useState(false)
  const login = JSON.parse(sessionStorage.getItem("status"))

 // console.log(user, "state");

  useEffect(() => {
    setTimeout(() => {
      setloading(true)
    }, 2000)
  }, [])


  return (

    <>
      <Homelayout>
      {loading !== true ? (
          <div className="loader_set">
            {" "}
            <img src={loaderImg} alt="loading..." />{" "}
          </div>
        ) :<>
        

        <Slider />
        <HomeServices />
        <WeAre />
        <ExploreSection />
        <NewsLetter />
        <NewsSection />

        </>}
      </Homelayout>

    </>
  )
}

export default Home
