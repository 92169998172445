import React from 'react'
import Layout from '../Layout/Layout'
import "../../Styles/mainstyle/style.css"

function Request() {
  return (
    <>
    <Layout>
    {/* <!-- request-view section start here --> */}
    <section className="request-view">
        <div className="container-sm">
            <div className="wrapper flex">
                <div className="col">
                    <h3>Request date and time</h3>
                    <div className="request-confirmation">
                      <p><strong>Your Appointment details are:</strong></p>
                      <p><strong>Name:</strong> nitintom11@gmail.com</p>
                      <p><strong>Email:</strong> nitintom11@gmail.com</p>
                      <p><strong>Phone Number:</strong> NA </p>
                      <p><strong>Date &amp; Time:</strong> 2022/12/27 20:35 </p>
                    </div>
                </div>
                <div className="col">
                    <h3>Property to be viewed</h3>
                    <div className="request-view-slider">
                        <div>
                            <figure>
                                <img src="app/images/related_properties_img.jpg" alt="" />
                            </figure>
                        </div>
                        <div>
                            <figure>
                                <img src="app/images/related_properties_img.jpg" alt="" />
                            </figure>
                        </div>
                        <div>
                            <figure>
                                <img src="app/images/related_properties_img.jpg" alt="" />
                            </figure>
                        </div>
                    </div>
                    <div className="listingaddress">
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"/></svg>
                        Costa de la Calma		      	
                    </div>
                    <div className="pricesection">
                        <span className="price">
                            <bdi>€805,000</bdi>
                        </span>
                        <span className="bedrooms">1 <img src="app/images/single-bed.png" /> </span>
                        <span className="bathrooms">1 <img src="app/images/bath.png" /> </span>
                        <span className="bathrooms_size"><img src="app/images/bathrooms_size.png" alt="" /> 55<i>m<sup>2</sup></i>  </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- request-view section end here --> */}



    </Layout>
    
    
    
    
    
    </>
  )
}

export default Request