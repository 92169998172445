import React, { useEffect } from 'react'
import Navbar from '../Header/Navbar'
import Footer from "../Footer/Footer"
import { useLocation } from 'react-router-dom'


function Homelayout(props) {

    return (
        <>
            <Navbar />
            {props.children}
            <Footer />
        </>
    )
}

export default Homelayout