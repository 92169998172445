import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../baseUrl";
import Layout from "../Layout/Layout";

function CreateNewPassword() {
  const { token } = useParams();
  const [checkError, setcheckError] = useState("Please Click on Check Box");
  const [loginError, setloginError] = useState();
  const navigate = useNavigate();
  const [success, setSucces] = useState("");
  const [formShow, setformShow] = useState();
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [verify_token, setverify_token] = useState("");

  console.log(success);

  const createNewPass = (e) => {
    e.preventDefault();
    if (token) {
      setverify_token(token);
    }
    const password = e.target.password.value;
    const password_confirmation = e.target.cpassword.value;

    const data = { verify_token, password, password_confirmation };
    console.log(data);
    axios
      .post(baseUrl + "/user/create-new-password", data, {
        headers: { Authorization: "No Auth" },
      })
      .then((response) => {
        console.log(" resp ", response);
        e.target.reset();
        if (response.status === 200) {
          //   setformShow();
          navigate("/signin");
          setSucces(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          // alert(error.message)
          setloginError(
            error.response.data.message + " ? Please Check Your Password !"
          );
        } else {
          setloginError("");
        }
      });
  };

  return (
    <>
      <Layout>
        <section className="account-edit lost_password">
          <div className="container-sm">
            <div className="wrapper">
              <div className="head flex">
                <div className="icon">
                  <img src="app/images/lock-icon.png" alt="" />
                </div>
                <h5>Create New Password</h5>
              </div>
              <p>
                Lost your password? Please enter your username or email address.
                You will receive a link to create a new password via email.
              </p>

              <>
                <form
                  className="editProfile"
                  onSubmit={(e) => createNewPass(e)}
                >
                  <div className="form_group">
                    <label for="password">Enter New Password</label>
                    <input type="text" name="password" id="password" />
                  </div>

                  <label for="password">Enter Confirm Password</label>
                  <input type="text" name="cpassword" id="cpassword" />

                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "300",
                      padding: "10px auto",
                    }}
                  >
                    {success}
                  </div>
                  <div style={{ fontSize: "12px", paddingBottom: "12px" }}>
                    {loginError}
                  </div>
                  <div className="form_group">
                    <button type="submit">Confirm</button>
                  </div>
                </form>
              </>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
}

export default CreateNewPassword;
