import React, { useState } from 'react'
 import "../../Styles/mainstyle/landingpage.css"
import logo from "../../assets/images/Bella-White-No-Logo.png"
import { NavLink } from 'react-router-dom'
function Loadingpage() {

const [Show,setShow] =useState(false)
const [Show2,setShow2] =useState(false)
const [Show3,setShow3] =useState(false)
const [Show4,setShow4] =useState(false)
const [Show5,setShow5] =useState(false)
const [Show6,setShow6] =useState(false)
const [Show7,setShow7] =useState(false)
const [Show8,setShow8] =useState(false)
const [Show9,setShow9] =useState(false)

const setShowhandle =(name)=>{
    console.log(name);
    switch (name) {
        case "one":
            setShow("one")
            break;
    
        default:
            break;
    }
}


  return (
    <>
     <div className="header landingpage">
        <div className="container">
            <div className="row justify-content-between align-items-center">
                <div className="logo">
                    <a href="/"><img src={logo} alt="logo..."/></a>
                </div>
                <div className="dropdown">
                    <a href="#">English(UK)<span><i className="fa fa-sort-desc" aria-hidden="true"></i></span></a>
                    <div className="dropdown-content">
                        <ul>
                            <li><a href="#">Español</a></li>
                            <li><a href="#">Deutsch</a></li>
                            <li><a href="#">Français</a></li>
                        </ul>
                    </div>
                    <div id="toggle">
                        <div className="one"></div>
                        <div className="two"></div>
                        <div className="three"></div>
                      </div>
                </div>
            </div>
        </div>
    </div>
    <section className="banner-section text-white">
        <div className="container">
            <div className="row  justify-content-between align-items-center">
                <div className="col-text">
                    <h1 style={{color:"#fff"}}>Mallorca properties at your fingertips</h1>
                    <p>The real estate portal to find homes for sale and rent in Mallorca. Search for the ideal house,
                        apartment or land.</p>
                    <a href="#" className="btn btn-default">I want to know more</a>
                </div>
                <div className="col-img">
                    <video controls>
                        <source src={require("../../assets/images/Join-Today.mp4")} type="video/mp4"/>
                        <source src="mov_bbb.ogg" type="video/ogg"/>
                        Your browser does not support HTML video.
                    </video>
                </div>
            </div>
        </div>
        <div className="banner-shape">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                <path fill="#fff" className="elementor-shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
            </svg>
        </div>
    </section>
    <section className="feature-section">
        <div className="container">
            <h2>For estate agents, If you sign up now you will receive</h2>
            <div className="row justify-content-between">
                <div className="column">
                    <span><i className="fa fa-star" aria-hidden="true"></i></span>
                    <h5>FREE membership for 12 months, with no commitment</h5>
                </div>
                <div className="column">
                    <span><i className="fa fa-coffee" aria-hidden="true"></i></span>
                    <h5>Transparent, fixed fees until 2024x</h5>
                </div>
                <div className="column">
                    <span><i className="fa fa-check-circle" aria-hidden="true"></i></span>
                    <h5>Easy integration with your CRM provider.</h5>
                </div>
                <div className="column">
                    <span><i className="fa fa-lock" aria-hidden="true"></i></span>
                    <h5>Secure greater exposure for your agency brand than on other portals</h5>
                </div>
            </div>
            <div className="text-center">
                <a href="#" className="btn btn-primary">SIGN UP TODAY</a>
            </div>
        </div>
    </section>
    <section className="step-section">
        <div className="container-sm">
            <h2>Quick and easy steps to buy or rent your property</h2>
            <div className="count-box align-items-center">
                <span className="count"></span>
                <div className="text">
                    <h3>Advice and tips when buying in Mallorca</h3>
                    <p>Whether you are Spanish, German or British, we have the latest advice to help you buy a property
                        in Mallorca.</p>
                </div>
            </div>
            <div className="count-box align-items-center">
                <span className="count"></span>
                <div className="text">
                    <h3>Be the first to see new properties</h3>
                    <p>Register with us to get instant property alerts so you never miss out.</p>
                </div>
            </div>
            <div className="count-box align-items-center">
                <span className="count"></span>
                <div className="text">
                    <h3>How much your home is worth</h3>
                    <p>Get an accurate market valuation from property experts you select.</p>
                </div>
            </div>
            <a href="#" className="btn btn-primary">I want know more</a>
        </div>
    </section>
    <section className="information-section">
        <div className="container">
            <h2>Do you need more information? <br/> No problem! Ask us anything</h2>
            <div className="row">
                <div className="col">

                    <div className="accordian">
                        <div className="accordian-head ">
                            <h5>Selling Mallorca</h5>
                            <i className="fa fa-chevron-down" name='one' onClick={()=>
                                {
                                    setShow(!Show);
                                    setShow2(false)
                                    setShow3(false)
                                    setShow4(false)
                                    setShow5(false)
                                    setShow6(false)
                                    setShow7(false)
                                    setShow8(false)
                                }
                                }></i>
                        </div>
                        <div className="accordian-body" style={Show == true ?{ display:"block" ,transition:"0.3s"} :{}} >
                            <p>Something that makes us unique is that we actively advertise the benefits of this amazing
                                Island to worldwide buyers and renters. Our aim is to educate and encourage a wide
                                audience
                                to realize the benefits of having a property in Mallorca.</p>
                        </div>
                    </div>
                    <div className="accordian">
                        <div className="accordian-head ">
                            <h5>We do not advertise to private sellers</h5>
                            <i className="fa fa-chevron-down" onClick={()=>
                                 {
                                    setShow(false);
                                    setShow3(false)
                                    setShow4(false)
                                    setShow5(false)
                                    setShow6(false)
                                    setShow7(false)
                                    setShow2(!Show2)
                                    setShow8(false)
                                }
                                }></i>
                        </div>
                        <div className="accordian-body" style={Show2 == true ?{ display:"block"} :{}}>
                            <p> Bella Islas only deals with estate agents and developers. Private sellers and landlords
                                cannot advertise on our website without an estate agent.</p>
                        </div>
                    </div>
                    <div className="accordian">
                        <div className="accordian-head ">
                            <h5>Pushing leads</h5>
                            <i className="fa fa-chevron-down" onClick={()=>{
                                    setShow(false);
                                    setShow3(!false)
                                    setShow4(false)
                                    setShow5(false)
                                    setShow6(false)
                                    setShow7(false)
                                    setShow2(false)
                                    setShow8(false)
                                }}></i>
                        </div>
                        <div className="accordian-body" style={Show3 == true ?{ display:"block"} :{}}>
                            <p>Sellers and landlords are now able to easily search for estate agents using our “find
                                agents” section. Here they will be given a selection of estate agents to get a valuation
                                from and the agents will immediately receive a request along with the clients’ details.
                                This feature was added to make the process of searching for an agent and arranging
                                multiple valuations much simpler for both the client and estate agent.</p>
                        </div>
                    </div>
                    <div className="accordian">
                        <div className="accordian-head ">
                            <h5>Advice for buyers</h5>
                            <i className="fa fa-chevron-down" onClick={()=>
                            {
                                setShow(false);
                                setShow3(false)
                                setShow4(!false)
                                setShow5(false)
                                setShow6(false)
                                setShow7(false)
                                setShow2(false)
                                setShow8(false)
                            }}></i>
                        </div>
                        <div className="accordian-body" style={Show4 == true ?{ display:"block"} :{}}>
                            <p>To make life easier we have a buying advice page for international buyers who are looking
                                to purchase a property in Mallorca.</p>
                            <p>This page is designed to help educate and simplify the buying process to encourage new
                                buyers. </p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="accordian">
                        <div className="accordian-head ">
                            <h5>Mallorca’s property market in one place</h5>
                            <i className="fa fa-chevron-down" onClick={()=>  {
                                setShow(false);
                                setShow3(false)
                                setShow4(false)
                                setShow5(!false)
                                setShow6(false)
                                setShow7(false)
                                setShow2(false)
                                setShow8(false)
                            }}></i>
                        </div>
                        <div className="accordian-body" style={Show5 == true ?{ display:"block"} :{}}>
                            <p> Searching for a property in Mallorca can be quite daunting; there are many agents and
                                the current property portals have a scattered selection of the market. The goal for
                                Bella Islas is to cover the vast majority of the property market in Mallorca to make
                                life easier for estate agents and buyers, sellers, renters and landlords..</p>
                        </div>
                    </div>
                    <div className="accordian">
                        <div className="accordian-head ">
                            <h5>We promote Estate Agents</h5>
                            <i className="fa fa-chevron-down" onClick={()=>  {
                                setShow(false);
                                setShow3(false)
                                setShow4(false)
                                setShow5(false)
                                setShow6(!false)
                                setShow7(false)
                                setShow2(false)
                                setShow8(false)
                            }}></i>
                        </div>
                        <div className="accordian-body" style={Show6 == true ?{ display:"block"} :{}}>
                            <p> Get your agency seen by buyers, sellers, renters, and landlords. They can learn about
                                your agency, your team and find where your office is located. They can also request a
                                valuation on the website if they are interested in selling or renting. This is perfect
                                for generating more leads and building your portfolio. Estate agents will also be able
                                to promote which properties they have recently sold and rented.</p>
                        </div>
                    </div>
                    <div className="accordian">
                        <div className="accordian-head ">
                            <h5>Data for estate agents to use</h5>
                            <i className="fa fa-chevron-down" onClick={()=>  {
                                setShow(false);
                                setShow3(false)
                                setShow4(false)
                                setShow5(false)
                                setShow6(false)
                                setShow7(false)
                                setShow2(false)
                                setShow8(!false)
                            }}></i>
                        </div>
                        <div className="accordian-body" style={Show8 == true ?{ display:"block"} :{}} >
                            <p>In the agent’s section of Bella Islas, agents have a dashboard so they can see which
                                property listings are performing well and how to improve underperformers.</p>
                        </div>
                    </div>
                    <div className="accordian">
                        <div className="accordian-head ">
                            <h5>Premium and featured properties</h5>
                            <i className="fa fa-chevron-down" onClick={()=>  {
                                setShow(false);
                                setShow3(false)
                                setShow4(false)
                                setShow5(false)
                                setShow6(false)
                                setShow7(!false)
                                setShow2(false)
                                setShow8(false)
                            }}></i>
                        </div>
                        <div className="accordian-body" style={Show7 == true ?{ display:"block"} :{}}>
                            <p>In the agent’s section of Bella Islas, agents have a dashboard so they can see which property listings are performing well and how to improve underperformers.</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
    <section className="offer-section">
        <div className="container">
            <h2>Do you have a property to sell or rent? With bella islas you can easily search for the right real estate
                agencies to suit you.
            </h2>
            <a href="#" className="btn btn-primary">I want know more</a>
        </div>
        <div className="elementor-shape elementor-shape-top" data-negative="false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                <path className="elementor-shape-fill"
                    d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z">
                </path>
            </svg>
        </div>
    </section>
    <section className="footer">
        <div className="container">
            <div className="row justify-content-between">
                <div className="menu">
                    <ul>
                        <li><a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i className="fa fa-youtube-play    " aria-hidden="true"></i></a></li>
                        <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                    </ul>
                </div>
                <p>© copyright Bella Islas 2022 </p>
            </div>
        </div>
    </section>
    
    
    
    </>
  )
}

export default Loadingpage