import React from 'react'
import { NavLink } from 'react-router-dom';

import { Homedata } from '../../../Data/Data'
import Layout from '../../Layout/Layout';




function HomeServices() {

   // console.log(Homedata);


    return (
        <>
            <section className="home-service" id="home-service">
                <div className="container-sm">
                    <ul className="flex">
                        {

                            Homedata.map((item, index) => {
                                return (


                                    <li data-aos="fade-up" key={index}>
                                        <NavLink to={item.path}>
                                            <figure>
                                                <img src={item.img} alt="img loading..." />
                                            </figure>
                                            <h3>{item.heading}</h3>
                                            <p>
                                                {item.description}
                                            </p>
                                        </NavLink>
                                    </li>

                                )
                            })
                        }

                    </ul>
                </div>
            </section>
        </>
    )
}

export default HomeServices
