import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { baseUrl } from "../../baseUrl";
import { blogdata } from "../../Data/Data";
import Layout from "../Layout/Layout";
import loader from "../../assets/images/loader.svg";
import moment from "moment"

function Blog() {
  const [blog, setblog] = useState();
  const [loading, setloading] = useState(false);

  //console.log(blog);

  const fetchblogdata = async () => {
    await axios
      .get(baseUrl + "/blog/blog-list", {
        headers: { Authorization: `No Auth` },
      })
      .then((res) => {
        if (res.status == 200) {
          setloading(true);
        }
        console.log(res);
        setblog(res.data.data);
      })
      .catch((error) => {
        if (error) {
          setloading(true);
        }
        console.log(error);
      });
  };

  useEffect(() => {
    fetchblogdata();
  }, []);

  return (
    <>
      <Layout>
        {loading !== true ? (
          <div className="loader_set">
            {" "}
            <img src={loader} alt="loading..." />{" "}
          </div>
        ) : (
          <>
            <section className="innerBanner blog_back">
              <div className="inner-heading">
                <h1>Articles</h1>
              </div>
            </section>

            <section className="blog-list">
              <div className="container-sm">
                <ul className="blog_resp">
                  {blog.map((item, index) => {
                    console.log(item.file_path);
                    return (
                      <li className="flex" key={item.id}>
                        <figure>
                          <a href={`/blog-details/${item.id}`}>
                            <img src={item.file_path} alt="img loading..." />
                          </a>
                        </figure>
                        <div className=" content_resp content">
                          <a href={`/article/${item.id}`} className="tag">
                            Articles
                          </a>
                          <h3>
                            <a href={`/blog-details/${item.id}`}>
                              {item.title}</a>
                          </h3>
                          <p>{item.short_desc}</p>
                          <p className="date">
                            <i
                              aria-hidden="true"
                              className="fa fa-calendar"
                            ></i>
                            <p>
                              {moment(item?.updated_at).format("MMM DD, YYYY")}
                            </p>
                          </p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </section>
          </>
        )}
      </Layout>
    </>
  );
}

export default Blog;
