import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { baseUrl } from "../../baseUrl";
import { aboutdata } from "../../Data/Data";
import Layout from "../Layout/Layout";
import loaderImg from "../../assets/images/loader.svg";

function About() {
  const [loading, setloading] = useState(false);

  const [about, setabout] = useState([]);
  console.log(about);
  const fetchtownList = async () => {
    await axios
      .get(baseUrl + `/about-us`, {
        headers: { Authorization: `No Auth` },
      })
      .then((res) => {
        console.log("townlist==", res);
        if (res.status == 200) {
          setabout(res.data.data);
          setloading(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          setloading(true);
        }
      });
  };

  const description = about[0] ? about[0].description : "";

  console.log(description);
  useEffect(() => {
    fetchtownList();
  }, []);

  return (
    <>
      <Layout>
        {loading !== true ? (
          <div className="loader_set">
            {" "}
            <img src={loaderImg} alt="loading..." />{" "}
          </div>
        ) : (
          <>
            <section className="innerBanner about_back">
              <div className="inner-heading">
                <h1>{about[0] ? about[0].title : ""}</h1>
                <h3>{about[0] ? about[0].sub_title : ""}</h3>
              </div>
            </section>

            <section className="about-content">
              <div className="container-sm">
                <div className="wrapper" data-aos="fade-in">
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </div>
              </div>
            </section>

            <section className="about-service">
              <div className="container-sm">
                <h3 data-aos="fade-in">
                  Whether you're buying, selling or renting <br /> we can help
                  you move forward.
                </h3>
                <ul className="flex flex_resp">
                  {aboutdata.map((item, index) => {
                    return (
                      <li data-aos="fade-up" key={index}>
                        <figure>
                          <img src={item.img} alt="img loading" />
                        </figure>
                        <h3>{item.heading}</h3>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </section>
          </>
        )}
      </Layout>
    </>
  );
}

export default About;
