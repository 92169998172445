export const Route = [
    {
        name: "Buy",
        path: "#"
    }
    ,
    {
        name: "Rent",
        path: "#"
    }
    ,
    {
        name: "Commercial",
        path: "#"
    }
    ,
    {
        name: "Buying Advice",
        path: "#"
    }
    ,
    {
        name: "Mallorca",
        path: "#"
    }
    ,
    {
        name: "English (UK)",
        path: "#"
    }


] 