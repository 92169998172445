import { createReducer } from "@reduxjs/toolkit"


const initialState ={
    user: {},
    buy: {} ,
    addedProducts: ""
}


const userReducer = createReducer(initialState ,(builder)=>{
    builder.addCase("USER_INFO", (state  ,action)=>{
        state.user = action.payload
    })
    

})

export default userReducer