import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { baseUrl } from "../../baseUrl";
import { blogdata } from "../../Data/Data";
import Layout from "../Layout/Layout";
import loader from "../../assets/images/loader.svg";
import moment from "moment"

function Article() {
  const [blog, setblog] = useState();
  const [loading, setloading] = useState(false);
  const { id } = useParams();

  const [resent, setRecent] = useState();
  const [itemid, setitemid] = useState("");
  //   console.log(blog);

  //   console.log(blog);

  const fetchblogdata = async () => {
    await axios
      .get(baseUrl + `/blog/blog-list?id=${id}`, {
        headers: { Authorization: `No Auth` },
      })
      .then((res) => {
        if (res.status == 200) {
          setloading(true);
          setblog(res.data);
          setRecent(res.data.message);


        }
        console.log(res);
        setblog(res.data.data);
      })
      .catch((error) => {
        if (error) {
          setloading(true);
        }
        console.log(error);
      });
  };

  useEffect(() => {
    fetchblogdata();
  }, []);

  return (
    <>
      <Layout>
        {loading !== true ? (
          <div className="loader_set">
            {" "}
            <img src={loader} alt="loading..." />{" "}
          </div>
        ) : (
          <>
            <section className="innerBanner blog_back">
              <div className="inner-heading">
                <h1>Articles</h1>
              </div>
            </section>

            <section className="blog-list second">
              <div className="container-sm">
                <h2>Recent News</h2>
                <ul>
                  {resent.recent_news?.map((item, index) => {
                    return (
                      <li className="flex" key={index}>
                        <figure>
                          <NavLink
                            to={`/blog-details/${item.id}`}
                            onClick={() => {
                              setitemid(item.id);
                            }}
                          >
                            <img src={item.file_path} alt="img.." />
                          </NavLink>
                        </figure>
                        <div className="content">
                          <h3>
                            <NavLink to={`/blog-details/${item.id}`}>
                              {item.title}
                            </NavLink>
                          </h3>
                          <p>{item.short_desc}</p>
                          <NavLink
                            to={`/blog-details/${item.id}`}
                            onClick={() => {
                              setitemid(item.id);
                            }}
                            className="readMore"
                          >
                            Read More
                          </NavLink>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </section>
          </>
        )}
      </Layout>
    </>
  );
}

export default Article;
