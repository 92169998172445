import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { baseUrl } from "../../baseUrl";
import { buyAdviceData } from "../../Data/Data";
import Layout from "../Layout/Layout";
import loader from "../../assets/images/loader.svg";

function BuyAdvice() {
  // console.log(buyAdviceData);
  const token = JSON.parse(localStorage.getItem("token"));
  const [adivcer, setadvicer] = useState();
  const [loading, setloading] = useState(false);

  console.log(adivcer);
  const fetchbuyadvice = async () => {
    await axios
      .get(baseUrl + "/buying-advice", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          setloading(true);
        }
        console.log(res);
        setadvicer(res.data.data);
      })
      .catch((error) => {
        if(error){
          setloading(true);

      }
        console.log(error);
      });
  };

  useEffect(() => {
    fetchbuyadvice();
  }, []);

  return (
    <>
      <Layout>
        {loading !== true ? (
          <div className="loader_set">
            {" "}
            <img src={loader} alt="loading..." />{" "}
          </div>
        ) : (
          <section className="buying-advice">
            <div className="container-sm">
              <div className="heading-line" data-aos="fade-in">
                <h3>Which buyer are you?</h3>
              </div>
              <ul className="flex_dirction_col flex">
                {adivcer?.map((item, index) => {
                  return (
                    <li data-aos="fade-up" key={index}>
                      <figure>
                        <img src={item.image} alt="loading img..." />
                      </figure>
                      <div className="content">
                        <h4>{item.buyer}</h4>
                        <NavLink to={`/buy-advice/${item.id}`} href={item.path}>
                          Learn More
                        </NavLink>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </section>
        )}
      </Layout>
    </>
  );
}

export default BuyAdvice;
