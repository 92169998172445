import React from 'react'
import Layout from '../Layout/Layout'
import Searchbar from '../Sections/SearchSections/Searchbar'
import PremiumSection from '../Sections/SearchSections/PremiumSection'
import BuySection from '../Sections/SearchSections/BuySection'

function Buy() {
    return (
        <>
            <Layout>
                <Searchbar />
            </Layout>
        </>
    )
}

export default Buy