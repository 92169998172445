import React from 'react'
import Footer from '../Footer/Footer'

import Dashboardheader from "./Dashboardheader"

function DashboardLayout(props) {
  return (
    <>
    <Dashboardheader/>
    
    {props.children}
    
    <Footer/>
    </>
  )
}

export default DashboardLayout