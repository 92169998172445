import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = (props) => {
   
    const islogin = JSON.parse(localStorage.getItem("login"))
    //console.log(islogin);

    return (islogin === true ? props.element : <Navigate to="/signin" />)
}
export default PrivateRoute